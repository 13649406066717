import { useMemo } from "react";
import { GenericGrid } from "./GenericGrid";
import {
	type GenericGridProps,
	type TypedGridColumnProps,
	toCurrency,
} from "./helpers";
import { JobStatusCell, toCell } from "./helpersReact";

type InvoicingCheck = {
	id: number;
	uniqueId: string;
	vatRate: number | undefined;
	vatRateString: string;
	assignedToName: string;
	cost: number | undefined;
	costString: string;
	price: number | undefined;
	customerId: number;
	customerName: string;
	customerCurrencyCode: string | undefined;
	customerInvoiceTypeString: string;
	grossSum: number | undefined;
	grossSumString: string;
	invoiceNumber: string;
	purchaseOrderNumber: string;
	status: number;
	statusString: string;
	totalLegsWithPods: number;
	totalLegsNeedingPods: number;
	ratioPodsString: string;
	startDate: Date;
	startDateString: string;
	endDate: Date;
	endDateString: string;
	invoiceDate: Date | undefined;
	invoiceDateString: string;
};

type InvoicingCheckGridProps = GenericGridProps<InvoicingCheck> & {
	onChange: (data: InvoicingCheck) => void;
};
export const InvoicingCheckGrid = ({
	extraColumns = [],
	onChange,
	...rest
}: InvoicingCheckGridProps) => {
	const defaultColumns: TypedGridColumnProps<InvoicingCheck>[] = useMemo(
		() => [
			{ field: "uniqueId", title: "Unique ID" },
			{
				field: "statusString",
				title: "Status",
				cell: ({ dataItem }) => (
					<JobStatusCell
						status={dataItem.status}
						name={dataItem.statusString}
					/>
				),
			},
			{ field: "customerName", title: "Customer" },
			{ field: "customerInvoiceTypeString", title: "Invoice Type" },
			{ field: "invoiceNumber", title: "Invoice Number" },
			{
				field: "startDate",
				title: "Start Date",
				cell: ({ dataItem }) => toCell(dataItem.startDateString),
			},
			{
				field: "endDate",
				title: "End Date",
				cell: ({ dataItem }) => toCell(dataItem.endDateString),
			},
			{
				field: "invoiceDate",
				title: "Invoice Date",
				cell: ({ dataItem }) => toCell(dataItem.invoiceDateString),
			},
			{ field: "ratioPodsString", title: "PODs" },
			{
				field: "vatRate",
				title: "VAT Rate",
				cell: ({ dataItem }) => toCell(dataItem.vatRateString),
			},
			{
				field: "cost",
				title: "Cost",
				cell: ({ dataItem }) => toCell(dataItem.costString),
			},
			{
				field: "grossSum",
				title: "Gross Sum",
				cell: ({ dataItem }) => toCell(dataItem.grossSumString),
			},
			{
				field: "price",
				title: "Price",
				cell: ({ dataItem }) => (
					<td style={{ textOverflow: "unset" }}>
						{toCurrency(0, dataItem.customerCurrencyCode)[0]}
						<input
							defaultValue={dataItem.price}
							onBlur={(x) =>
								onChange({
									...dataItem,
									price: Number.parseFloat(x.target.value),
								})
							}
							style={{ width: "110px" }}
						/>
					</td>
				),
			},
			{
				field: "customerCurrencyCode",
				title: "Currency",
				hidden: true,
			},
			{
				field: "purchaseOrderNumber",
				title: "Purchase Order Number",
				cell: ({ dataItem }) => (
					<td>
						<input
							defaultValue={dataItem.purchaseOrderNumber}
							onBlur={(x) =>
								onChange({ ...dataItem, purchaseOrderNumber: x.target.value })
							}
							style={{ width: "110px" }}
						/>
					</td>
				),
			},
			{ field: "assignedToName", title: "Assigned To" },
			{ field: "customerCurrencyCode", title: "Currency Code" }, // Added currency code field
		],
		[onChange],
	);
	const columns = useMemo(
		() => [...defaultColumns, ...extraColumns],
		[defaultColumns, extraColumns],
	);
	return (
		<GenericGrid defaultColumns={columns} name="InvoicingCheck" {...rest} />
	);
};
