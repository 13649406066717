import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, array, number, object, string } from "yup";
import { Flex } from "./GenericFlex";
import {
	GenericFormButtons,
	InputMultiSelect,
	InputSelect,
	InputText,
	type LoadOptionsFn,
} from "./GenericForm";
import { GenericPage } from "./GenericPage";
import type { UserStatus } from "./api/AuthenticationApi";
import {
	type TypedGridColumnProps,
	authenticationApi,
	jobApi,
	toasted,
	useLookupBusinessUnits,
	useLookupDrivers,
	useLookupRoles,
	useLookupUserStatuses,
	userStatusNames,
} from "./helpers";

const UserSchema = object({
	id: string().label("ID"),
	driverId: number().nullable().label("Driver"),
	businessUnitIds: array()
		.of(number().required().label("Business Unit"))
		.label("Business Units"),
	firstName: string().label("First Name").required(),
	lastName: string().label("Last Name").required(),
	roleId: string().label("Role").required(),
	status: number().label("Status").required(),
	email: string().label("Email").email().required(),
});
type UserFormObject = InferType<typeof UserSchema>;

type UserFormProps = {
	defaultValues?: Partial<UserFormObject>;
	onSubmit: (data: UserFormObject) => void;
	lookupDrivers: LoadOptionsFn;
	lookupRoles: LoadOptionsFn;
	lookupUserStatuses: LoadOptionsFn;
	lookupBusinessUnits: LoadOptionsFn;
};
const UserForm = ({
	defaultValues,
	onSubmit,
	lookupDrivers,
	lookupRoles,
	lookupUserStatuses,
	lookupBusinessUnits,
}: UserFormProps) => {
	const { handleSubmit, reset, control } = useForm<UserFormObject>({
		resolver: yupResolver(UserSchema),
		defaultValues,
	});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit((data) => onSubmit?.(data))}
		>
			<Flex>
				<div>
					<InputText control={control} schema={UserSchema} name="firstName" />
					<InputText control={control} schema={UserSchema} name="lastName" />
					<InputText control={control} schema={UserSchema} name="email" />
				</div>
				<div>
					<InputSelect
						control={control}
						schema={UserSchema}
						name="roleId"
						loadOptions={lookupRoles}
					/>
					<InputSelect
						control={control}
						schema={UserSchema}
						name="driverId"
						loadOptions={lookupDrivers}
					/>
					<InputSelect
						control={control}
						schema={UserSchema}
						name="status"
						loadOptions={lookupUserStatuses}
					/>
					<InputMultiSelect
						control={control}
						schema={UserSchema}
						name="businessUnitIds"
						loadOptions={lookupBusinessUnits}
					/>
				</div>
			</Flex>
			<GenericFormButtons onReset={() => reset(defaultValues)} />
		</form>
	);
};
const UserFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<UserFormProps, "onSubmit" | "defaultValues">) => {
	const lookupDrivers = useLookupDrivers();
	const lookupRoles = useLookupRoles();
	const lookupUserStatuses = useLookupUserStatuses();
	const lookupBusinessUnits = useLookupBusinessUnits();
	return (
		<UserForm
			defaultValues={defaultValues}
			lookupDrivers={lookupDrivers}
			lookupRoles={lookupRoles}
			lookupUserStatuses={lookupUserStatuses}
			lookupBusinessUnits={lookupBusinessUnits}
			onSubmit={async (data) => {
				const { id, status, businessUnitIds, ...rest } = data;
				let userId = id;
				const processData = async () => {
					if (id)
						await authenticationApi.user.userUpdate({
							id,
							status: status as UserStatus,
							...rest,
						});
					else {
						const result = await authenticationApi.user.userCreate(rest);
						userId = result.data.id;
					}
					if (userId && businessUnitIds)
						await authenticationApi.user.userLinkToBusinessUnitCreate(
							userId,
							businessUnitIds,
						);
					onSubmit(data);
				};
				await toasted(processData(), id ? "Updating user" : "Creating user");
			}}
		/>
	);
};
type User = UserFormObject & {
	id: string;
	name: string;
	driverString: string;
	roleString: string;
	statusString: string;
	businessUnitString: string;
};
const defaultColumns: TypedGridColumnProps<User>[] = [
	{ field: "id", title: "ID" },
	{ field: "name", title: "Name" },
	{ field: "roleString", title: "Role" },
	{ field: "driverString", title: "Driver" },
	{ field: "statusString", title: "Status" },
	{ field: "email", title: "Email" },
	{ field: "businessUnitString", title: "Business Units" },
];
const useFetchData = (): ComponentProps<typeof GenericPage<User>>["data"] => {
	const _users = useQuery({
		queryKey: ["authenticationApi.user.userList"],
		queryFn: () => authenticationApi.user.userList({}).then((x) => x.data.data),
		initialData: [],
	});
	const _businessUnits = useQuery({
		queryKey: ["authenticationApi.businessUnit.businessUnitLookupList"],
		queryFn: () =>
			authenticationApi.businessUnit
				.businessUnitLookupList()
				.then((x) => x.data),
		initialData: [],
	});
	const _drivers = useQuery({
		queryKey: ["jobApi.driver.driverLookupList"],
		queryFn: () => jobApi.driver.driverLookupList({}).then((x) => x.data),
		initialData: [],
	});
	const users = useMemo(
		() =>
			_users.data.map(
				(user): User => ({
					id: user.id,
					firstName: user.firstName,
					lastName: user.lastName,
					name: `${user.firstName} ${user.lastName}`,
					roleId: user.roles[0]?.id ?? "",
					driverId: user.driverId,
					status: user.status,
					email: user.email,
					driverString:
						_drivers.data.find((x) => x.id === user.driverId)?.name ?? "",
					roleString: user.roles[0]?.name ?? "",
					statusString: userStatusNames[user.status] ?? "",
					businessUnitIds: user.businessUnitIds,
					businessUnitString: user.businessUnitIds
						.map((x) => _businessUnits.data.find((b) => b.id === `${x}`)?.name)
						.join(", "),
				}),
			) ?? [],
		[_users.data, _businessUnits.data, _drivers.data],
	);
	return {
		data: users,
		retry: _users.refetch,
		loading: _users.isFetching,
	};
};

export const UsersPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: string) =>
		toasted(
			authenticationApi.user.userDelete(id).then(data.retry),
			"Deleting user",
		);
	const getForm = (
		_id: string | undefined,
		onSubmit: (data: UserFormObject) => void,
	) => {
		let defaultValues: Partial<UserFormObject> = { status: 1 };
		if (_id) defaultValues = data.data.find((x) => x.id === _id) ?? {};
		return (
			<UserFormWithDTO onSubmit={onSubmit} defaultValues={defaultValues} />
		);
	};
	return (
		<GenericPage
			pageTitle="Users"
			name="User"
			data={data}
			extraActions={[
				{
					name: "Resend activation email",
					onClick: async (x) => {
						await toasted(
							authenticationApi.authentication.authenticationResendActivationEmailCreate(
								{ email: x.email },
							),
							"Sending activation email",
						);
					},
				},
				{
					name: "Send forgot password email",
					onClick: async (x) => {
						await toasted(
							authenticationApi.authentication.authenticationForgotPasswordCreate(
								{ email: x.email },
							),
							"Sending forgot password email",
						);
					},
				},
			]}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};
