import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { type ReactNode, useMemo, useState } from "react";
import { useTitle } from "react-use";
import { GenericGrid } from "./GenericGrid";
import { TypedDropDownButton } from "./TypedDropDownButton";
import type { TypedGridColumnProps, WithId, WithName } from "./helpers";
import { useDialog } from "./helpersReact";

type StatusItem<T> = {
	text?: string;
	data: T;
	onClick: () => void;
};

const Status = ({ statusString }: { statusString: string }) => (
	<span>
		<Icon name="circle" />
		{statusString}
	</span>
);

const StatusItemRender = <T,>({ item }: { item: StatusItem<T> }) => (
	<Status statusString={item.text ?? ""} />
);

const useExtraColumns = <T,>(
	onEdit: (dataItem: T) => void,
	onDelete: (dataItem: T) => void,
	extraActions?: { name: string; onClick: (x: T) => void }[],
) =>
	useMemo(() => {
		const _columns: TypedGridColumnProps<T>[] = [
			{
				title: "Actions",
				cell: ({ dataItem }) => {
					const items =
						extraActions?.map((x) => ({
							text: x.name,
							data: x,
							onClick: () => x.onClick(dataItem),
						})) ?? [];
					return (
						<td>
							<Button
								size="small"
								icon="edit"
								onClick={() => onEdit(dataItem)}
							/>
							<Button
								size="small"
								icon="delete"
								onClick={() => onDelete(dataItem)}
							/>
							{extraActions && (
								<TypedDropDownButton
									size="small"
									icon="more-vertical"
									items={items}
									itemRender={StatusItemRender}
									onItemClick={(x) => x.item.onClick()}
									popupSettings={{ animate: false }}
								/>
							)}
						</td>
					);
				},
				field: "actions",
				width: extraActions ? "105px" : "70px",
			},
		];
		return _columns;
	}, [onEdit, onDelete, extraActions]);
type DeleteProps = {
	name: string;
	handleConfirm: () => void;
	toggleDialog: () => void;
};
const Delete = ({ name, handleConfirm, toggleDialog }: DeleteProps) => {
	return (
		<>
			Delete <b>{name}</b>?
			<DialogActionsBar>
				<Button onClick={toggleDialog}>No</Button>
				<Button onClick={handleConfirm} autoFocus>
					Yes
				</Button>
			</DialogActionsBar>
		</>
	);
};
type GenericPageProps<T extends WithId> = {
	pageTitle: string;
	name: string;
	data: { data: T[]; retry: () => void; loading: boolean };
	defaultColumns: TypedGridColumnProps<T>[];
	extraActions?: { name: string; onClick: (x: T) => void }[];
	onDelete: (id: T["id"]) => Promise<void>;
	getForm: (id: T["id"] | undefined, onSubmit: () => void) => ReactNode;
	extraButtons?: ReactNode;
};
export const GenericPage = <T extends WithId & WithName>({
	data,
	pageTitle,
	name,
	defaultColumns,
	extraActions,
	extraButtons,
	onDelete,
	getForm,
}: GenericPageProps<T>) => {
	useTitle(pageTitle);
	const [dialogTitle, setDialogTitle] = useState<string>();
	const [dialogContent, setDialogContent] = useState<ReactNode>();
	const [toggleDialog, dialog] = useDialog(dialogContent, dialogTitle);
	const getDeleteContent = (selected: T) => (
		<Delete
			name={selected.name}
			toggleDialog={toggleDialog}
			handleConfirm={async () => {
				await onDelete(selected.id);
				data.retry();
				toggleDialog();
			}}
		/>
	);
	const handleEdit = (x: T) => {
		setDialogTitle(`Edit ${x.name}`);
		setDialogContent(
			getForm(x.id, () => {
				data.retry();
				toggleDialog();
			}),
		);
		toggleDialog();
	};
	const handleDelete = (x: T) => {
		setDialogTitle(`Delete ${x.name}`);
		setDialogContent(getDeleteContent(x));
		toggleDialog();
	};
	const handleCreate = () => {
		setDialogTitle(`Create ${name}`);
		setDialogContent(
			getForm(undefined, () => {
				data.retry();
				toggleDialog();
			}),
		);
		toggleDialog();
	};
	const extraColumns = useExtraColumns(handleEdit, handleDelete, extraActions);
	return (
		<>
			<GenericGrid
				name={name}
				data={data.data}
				defaultColumns={defaultColumns}
				refresh={data.retry}
				loading={data.loading}
				extraColumns={extraColumns}
				extraButtons={
					<>
						<Button icon="plus" themeColor="primary" onClick={handleCreate}>
							Add {name}
						</Button>
						{extraButtons}
					</>
				}
			/>
			{dialog}
		</>
	);
};
