import {
	Field,
	Form,
	FormElement,
	type FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { GridLayout } from "@progress/kendo-react-layout";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { IBase } from "../../../../../common/models/src/lib/interfaces/base.interface";
import type { RadioGroupItem } from "../../../../../common/models/src/lib/interfaces/radio-group-item.interface";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumButton } from "../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { CelerumFormRadioGroup } from "../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import {
	fetchBusinessUnitAction,
	selectBusinessUnitAction,
} from "../../../../data-access/src/lib/authentication.slice";
import { navigateAfterBusinessUnitSelection } from "../helpers/authentication.helpers";
import styles from "./business-unit-selection.module.scss";

interface BusinessUnitSelectionProps {
	logoutHandler: () => void;
}

export const BusinessUnitSelection = ({
	logoutHandler,
}: BusinessUnitSelectionProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { businessUnits, currentBusinessUnit } = useAppSelector(
		(state) => state.authentication,
	);

	const renderedBusinessUnits = useMemo(() => {
		if (!businessUnits) return [];
		return businessUnits.map((item: IBase) => ({
			label: item.name,
			value: item.id,
		}));
	}, [businessUnits]) as RadioGroupItem[];

	const renderedCurrentBusinessUnit = useMemo(() => {
		if (!currentBusinessUnit) return undefined;
		return { label: currentBusinessUnit.name, value: currentBusinessUnit.id };
	}, [currentBusinessUnit]) as RadioGroupItem;

	const handleSubmit = async (event: FormSubmitClickEvent) => {
		for (const businessUnit of renderedBusinessUnits) {
			if (businessUnit.value === event.values.businessUnitId) {
				dispatch(
					selectBusinessUnitAction({
						id: businessUnit.value,
						name: businessUnit.label,
					}),
				);

				await dispatch(fetchBusinessUnitAction(businessUnit.value as string));
				/**
				 * Delayed navigation to ensure updated BU is used by axios in its request interceptor.
				 * delay value provided of 1 ms,
				 * allowing navigation to occur in the next iteration of the JS event loop.
				 */
				navigateAfterBusinessUnitSelection(navigate, location);
			}
		}
	};

	return (
		<GridLayout
			align={{
				horizontal: "center",
			}}
		>
			<CelerumCard className={styles.card}>
				<div className={styles.titleContainer}>
					<h1 className={styles.title}>Log into Celerum TLS</h1>
					<h3 className={styles.description}>
						Please select your business unit below:
					</h3>
				</div>
				{renderedBusinessUnits.length ? (
					<Form
						initialValues={{
							businessUnitId: renderedCurrentBusinessUnit
								? renderedCurrentBusinessUnit.value
								: renderedBusinessUnits[0]?.value,
						}}
						onSubmitClick={handleSubmit}
						render={() => (
							<FormElement>
								<div className={styles.formContainer}>
									<Field
										id="businessUnitId"
										name="businessUnitId"
										component={CelerumFormRadioGroup}
										data={renderedBusinessUnits}
									/>
								</div>
								<div className={styles.buttonContainer}>
									<CelerumButton
										style={{ width: "100%" }}
										type="submit"
										title="Continue"
									/>
								</div>
							</FormElement>
						)}
					/>
				) : (
					<div>
						<p>
							This account doesn't have any business units attached yet! Please
							come back later!
						</p>
						<CelerumButton title="Logout" onClick={logoutHandler} />
					</div>
				)}
			</CelerumCard>
		</GridLayout>
	);
};
