import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, object, string } from "yup";
import { GenericFormButtons, InputText } from "./GenericForm";
import { GenericPage } from "./GenericPage";
import {
	type TypedGridColumnProps,
	authenticationApi,
	toasted,
} from "./helpers";

const OrganisationSchema = object({
	id: string().label("ID"),
	name: string().label("Name").required(),
	description: string().label("Description").required(),
});
type OrganisationFormObject = InferType<typeof OrganisationSchema>;

type OrganisationFormProps = {
	defaultValues?: Partial<OrganisationFormObject>;
	onSubmit: (data: OrganisationFormObject) => void;
};
const OrganisationForm = ({
	defaultValues,
	onSubmit,
}: OrganisationFormProps) => {
	const { handleSubmit, reset, control } = useForm<OrganisationFormObject>({
		resolver: yupResolver(OrganisationSchema),
		defaultValues,
	});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit((data) => onSubmit?.(data))}
		>
			<InputText control={control} schema={OrganisationSchema} name="name" />
			<InputText
				control={control}
				schema={OrganisationSchema}
				name="description"
			/>
			<GenericFormButtons onReset={() => reset(defaultValues)} />
		</form>
	);
};
const OrganisationFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<OrganisationFormProps, "onSubmit" | "defaultValues">) => {
	return (
		<OrganisationForm
			defaultValues={defaultValues}
			onSubmit={async (data) => {
				const { id, ...rest } = data;
				const processData = async () => {
					if (id)
						await authenticationApi.organisation.organisationUpdate({
							id,
							...rest,
						});
					else await authenticationApi.organisation.organisationCreate(rest);
					onSubmit(data);
				};
				toasted(
					processData(),
					id ? "Updating Organisation" : "Creating Organisation",
				);
			}}
		/>
	);
};
type Organisation = OrganisationFormObject & {
	id: string;
};
const defaultColumns: TypedGridColumnProps<Organisation>[] = [
	{ field: "id", title: "ID" },
	{ field: "name", title: "Name" },
	{ field: "description", title: "Description" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<Organisation>
>["data"] => {
	const _organisations = useQuery({
		queryKey: ["authenticationApi.organisation.organisationList"],
		queryFn: () =>
			authenticationApi.organisation
				.organisationList({})
				.then((x) => x.data.data),
		initialData: [],
	});
	const organisations = useMemo(
		() =>
			_organisations.data.map(
				(x): Organisation => ({
					id: x.id,
					name: x.name,
					description: x.description,
				}),
			),
		[_organisations.data],
	);
	return {
		data: organisations,
		retry: _organisations.refetch,
		loading: _organisations.isFetching,
	};
};
export const OrganisationsPage = () => {
	const data = useFetchData();
	const handleDelete = (id: string) =>
		toasted(
			authenticationApi.organisation.organisationDelete(id).then(data.retry),
			"Deleting Organisation",
		);
	const getForm = (
		_id: string | undefined,
		onSubmit: (data: OrganisationFormObject) => void,
	) => {
		let defaultValues: Partial<OrganisationFormObject> = {};
		if (_id)
			defaultValues = data.data.find((x) => x.id === _id) ?? defaultValues;
		return (
			<OrganisationFormWithDTO
				onSubmit={onSubmit}
				defaultValues={defaultValues}
			/>
		);
	};
	return (
		<GenericPage
			pageTitle="Organisations"
			name="Organisation"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};
