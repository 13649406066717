import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, number, object, string } from "yup";
import {
	GenericFormButtons,
	InputSelect,
	InputText,
	type LoadOptionsFn,
} from "./GenericForm";
import { GenericPage } from "./GenericPage";
import {
	type TypedGridColumnProps,
	authenticationApi,
	jobApi,
	toasted,
	useLookupCurrencies,
} from "./helpers";

const BusinessUnitBankSchema = object({
	id: number().label("ID"),
	accountNumber: string().label("Account Number"),
	currencyId: number().required().label("Currency"),
	iban: string().label("IBAN"),
	sortCode: string().label("Sort Code"),
	vatRegNumber: string().label("VAT Reg Number"),
});
type BusinessUnitBankFormObject = InferType<typeof BusinessUnitBankSchema>;

type BusinessUnitBankFormProps = {
	defaultValues?: Partial<BusinessUnitBankFormObject>;
	onSubmit: (data: BusinessUnitBankFormObject) => void;
	lookupCurrencies: LoadOptionsFn;
};
const BusinessUnitBankForm = ({
	defaultValues,
	onSubmit,
	lookupCurrencies,
}: BusinessUnitBankFormProps) => {
	const { handleSubmit, reset, control } = useForm<BusinessUnitBankFormObject>({
		resolver: yupResolver(BusinessUnitBankSchema),
		defaultValues,
	});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit((data) => onSubmit?.(data))}
		>
			<InputText
				control={control}
				schema={BusinessUnitBankSchema}
				name="accountNumber"
			/>
			<InputSelect
				control={control}
				schema={BusinessUnitBankSchema}
				loadOptions={lookupCurrencies}
				name="currencyId"
			/>
			<InputText
				control={control}
				schema={BusinessUnitBankSchema}
				name="iban"
			/>
			<InputText
				control={control}
				schema={BusinessUnitBankSchema}
				name="sortCode"
			/>
			<InputText
				control={control}
				schema={BusinessUnitBankSchema}
				name="vatRegNumber"
			/>
			<GenericFormButtons onReset={() => reset(defaultValues)} />
		</form>
	);
};
const BusinessUnitBankFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<BusinessUnitBankFormProps, "onSubmit" | "defaultValues">) => {
	const lookupCurrencies = useLookupCurrencies();
	return (
		<BusinessUnitBankForm
			defaultValues={defaultValues}
			onSubmit={async (data) => {
				const { id, ...rest } = data;
				const processData = async () => {
					if (id)
						await authenticationApi.businessUnitBank.businessUnitBankUpdate({
							id,
							...rest,
						});
					else
						await authenticationApi.businessUnitBank.businessUnitBankCreate(
							rest,
						);
					onSubmit(data);
				};
				await toasted(
					processData(),
					id ? "Updating Business Unit Bank" : "Creating Business Unit Bank",
				);
			}}
			lookupCurrencies={lookupCurrencies}
		/>
	);
};

type BusinessUnitBank = BusinessUnitBankFormObject & {
	id: number;
	name: string;
	currencyString: string;
};
const defaultColumns: TypedGridColumnProps<BusinessUnitBank>[] = [
	{ field: "id", title: "ID" },
	{ field: "name", title: "Account Number" },
	{ field: "currencyString", title: "Currency" },
	{ field: "iban", title: "IBAN" },
	{ field: "sortCode", title: "Sort Code" },
	{ field: "vatRegNumber", title: "VAT Reg Number" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<BusinessUnitBank>
>["data"] => {
	const _businessUnitBanks = useQuery({
		queryKey: ["authenticationApi.businessUnitBank.businessUnitBankList"],
		queryFn: () =>
			authenticationApi.businessUnitBank
				.businessUnitBankList({})
				.then((x) => x.data),
		initialData: [],
	});
	const _currencies = useQuery({
		queryKey: ["jobApi.currency.currencyList"],
		queryFn: () => jobApi.currency.currencyList({}).then((x) => x.data.data),
		initialData: [],
	});
	const businessUnitBanks = useMemo(
		() =>
			_businessUnitBanks.data.map(
				(x): BusinessUnitBank => ({
					id: x.id,
					name: x.accountNumber ?? "",
					accountNumber: x.accountNumber ?? "",
					currencyId: x.currencyId ?? 0,
					iban: x.iban ?? "",
					sortCode: x.sortCode ?? "",
					vatRegNumber: x.vatRegNumber ?? "",
					currencyString:
						_currencies.data.find((y) => y.id === x.currencyId)?.code ?? "",
				}),
			),
		[_businessUnitBanks.data, _currencies.data],
	);
	return {
		data: businessUnitBanks,
		retry: _businessUnitBanks.refetch,
		loading: _businessUnitBanks.isFetching,
	};
};
export const BusinessUnitBanksPage = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			authenticationApi.businessUnitBank
				.businessUnitBankDelete(id)
				.then(data.retry),
			"Deleting Business Unit Bank",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: BusinessUnitBankFormObject) => void,
	) => {
		let defaultValues: Partial<BusinessUnitBankFormObject> = {};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<BusinessUnitBankFormWithDTO
				onSubmit={onSubmit}
				defaultValues={defaultValues}
			/>
		);
	};
	return (
		<GenericPage
			pageTitle="Business Unit Banks"
			name="Business Unit Bank"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};
