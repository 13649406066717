import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, number, object, string } from "yup";
import { GenericFormButtons, InputText, InputTextArea } from "./GenericForm";
import { GenericPage } from "./GenericPage";
import {
	type TypedGridColumnProps,
	authenticationApi,
	toasted,
} from "./helpers";

const BusinessUnitSettingSchema = object({
	id: number().label("ID"),
	key: string().required().label("Key"),
	value: string().label("Value"),
});
type BusinessUnitSettingFormObject = InferType<
	typeof BusinessUnitSettingSchema
>;

type BusinessUnitSettingFormProps = {
	defaultValues?: Partial<BusinessUnitSettingFormObject>;
	onSubmit: (data: BusinessUnitSettingFormObject) => void;
};
const BusinessUnitSettingForm = ({
	defaultValues,
	onSubmit,
}: BusinessUnitSettingFormProps) => {
	const { handleSubmit, reset, control } =
		useForm<BusinessUnitSettingFormObject>({
			resolver: yupResolver(BusinessUnitSettingSchema),
			defaultValues,
		});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit((data) => onSubmit?.(data))}
		>
			<InputText
				control={control}
				schema={BusinessUnitSettingSchema}
				name="key"
			/>
			<InputTextArea
				control={control}
				schema={BusinessUnitSettingSchema}
				name="value"
			/>
			<GenericFormButtons onReset={() => reset(defaultValues)} />
		</form>
	);
};
const BusinessUnitSettingFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<BusinessUnitSettingFormProps, "onSubmit" | "defaultValues">) => {
	return (
		<BusinessUnitSettingForm
			defaultValues={defaultValues}
			onSubmit={async (data) => {
				const { id, ...rest } = data;
				const processData = async () => {
					if (id)
						await authenticationApi.businessUnitSettings.businessUnitSettingsUpdate(
							{
								id,
								...rest,
							},
						);
					else
						await authenticationApi.businessUnitSettings.businessUnitSettingsCreate(
							rest,
						);
					onSubmit(data);
				};
				toasted(
					processData(),
					id
						? "Updating Business Unit Setting"
						: "Creating Business Unit Setting",
				);
			}}
		/>
	);
};

type BusinessUnitSetting = BusinessUnitSettingFormObject & {
	id: number;
	name: string;
};
const defaultColumns: TypedGridColumnProps<BusinessUnitSetting>[] = [
	{ field: "id", title: "ID" },
	{ field: "key", title: "Key" },
	{ field: "value", title: "Value" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<BusinessUnitSetting>
>["data"] => {
	const _businessUnitSettings = useQuery({
		queryKey: [
			"authenticationApi.businessUnitSettings.businessUnitSettingsList",
		],
		queryFn: () =>
			authenticationApi.businessUnitSettings
				.businessUnitSettingsList({})
				.then((x) => x.data),
		initialData: [],
	});
	const businessUnitSettings = useMemo(
		() =>
			_businessUnitSettings.data.map(
				(x): BusinessUnitSetting => ({
					id: x.id,
					name: x.key ?? "",
					key: x.key ?? "",
					value: x.value ?? "",
				}),
			),
		[_businessUnitSettings.data],
	);
	return {
		data: businessUnitSettings,
		retry: _businessUnitSettings.refetch,
		loading: _businessUnitSettings.isRefetching,
	};
};
export const BusinessUnitSettingsPage = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			authenticationApi.businessUnitSettings
				.businessUnitSettingsDelete(id)
				.then(data.retry),
			"Deleting Business Unit Setting",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: BusinessUnitSettingFormObject) => void,
	) => {
		let defaultValues: Partial<BusinessUnitSettingFormObject> = {};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<BusinessUnitSettingFormWithDTO
				onSubmit={onSubmit}
				defaultValues={defaultValues}
			/>
		);
	};
	return (
		<GenericPage
			pageTitle="Business Unit Settings"
			name="Business Unit Setting"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};
