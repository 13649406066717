import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, array, number, object, string } from "yup";
import { Flex } from "./GenericFlex";
import {
	GenericFormButtons,
	InputMultiCreate,
	InputMultiSelect,
	InputText,
	InputTextArea,
	type LoadOptionsFn,
} from "./GenericForm";
import { GenericPage } from "./GenericPage";
import {
	type TypedGridColumnProps,
	jobApi,
	toasted,
	useLookupConstraints,
	useLookupTrailerTypes,
	useLookupTruckTypes,
} from "./helpers";

const SubcontractorSchema = object({
	id: number().label("ID"),
	name: string().required().label("Name"),
	emails: array()
		.of(string().email().required().label("Email"))
		.label("Emails"),
	phones: array().of(string().required().label("Phone")).label("Phones"),
	notes: string().label("Notes").max(50),
	trailerTypeIds: array()
		.of(number().required().label("Trailer Type"))
		.label("Trailer Types"),
	truckTypeIds: array()
		.of(number().required().label("Truck Type"))
		.label("Truck Types"),
	truckRegistrationNumbers: array()
		.of(string().required().label("Truck Registration Number"))
		.label("Truck Registration Numbers"),
	constraintIds: array()
		.of(number().required().label("Constraint"))
		.label("Constraints"),
});
type SubcontractorFormObject = InferType<typeof SubcontractorSchema>;

type SubcontractorFormProps = {
	defaultValues?: Partial<SubcontractorFormObject>;
	onSubmit: (data: SubcontractorFormObject) => void;
	lookupTrailerTypes: LoadOptionsFn;
	lookupTruckTypes: LoadOptionsFn;
	lookupConstraints: LoadOptionsFn;
};
const SubcontractorForm = ({
	defaultValues,
	onSubmit,
	lookupConstraints,
	lookupTrailerTypes,
	lookupTruckTypes,
}: SubcontractorFormProps) => {
	const { handleSubmit, reset, control } = useForm<SubcontractorFormObject>({
		resolver: yupResolver(SubcontractorSchema),
		defaultValues,
	});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit((data) => onSubmit?.(data))}
		>
			<Flex>
				<div>
					<InputText
						control={control}
						schema={SubcontractorSchema}
						name="name"
					/>
					<InputMultiCreate
						control={control}
						schema={SubcontractorSchema}
						name="emails"
					/>
					<InputMultiCreate
						control={control}
						schema={SubcontractorSchema}
						name="phones"
					/>
					<InputTextArea
						control={control}
						schema={SubcontractorSchema}
						name="notes"
					/>
				</div>
				<div>
					<InputMultiSelect
						control={control}
						schema={SubcontractorSchema}
						name="trailerTypeIds"
						loadOptions={lookupTrailerTypes}
					/>
					<InputMultiSelect
						control={control}
						schema={SubcontractorSchema}
						name="truckTypeIds"
						loadOptions={lookupTruckTypes}
					/>
					<InputMultiCreate
						control={control}
						schema={SubcontractorSchema}
						name="truckRegistrationNumbers"
					/>
					<InputMultiSelect
						control={control}
						schema={SubcontractorSchema}
						name="constraintIds"
						loadOptions={lookupConstraints}
					/>
				</div>
			</Flex>
			<GenericFormButtons onReset={() => reset(defaultValues)} />
		</form>
	);
};
const SubcontractorFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<SubcontractorFormProps, "onSubmit" | "defaultValues">) => {
	const lookupTrailerTypes = useLookupTrailerTypes();
	const lookupTruckTypes = useLookupTruckTypes();
	const lookupConstraints = useLookupConstraints();
	return (
		<SubcontractorForm
			defaultValues={defaultValues}
			lookupConstraints={lookupConstraints}
			lookupTrailerTypes={lookupTrailerTypes}
			lookupTruckTypes={lookupTruckTypes}
			onSubmit={async (data) => {
				const { id, ...rest } = data;
				const processData = async () => {
					if (id)
						await jobApi.subcontractor.subcontractorUpdate({ id, ...rest });
					else await jobApi.subcontractor.subcontractorCreate({ ...rest });
					onSubmit(data);
				};
				toasted(
					processData(),
					id ? "Updating Subcontractor" : "Creating Subcontractor",
				);
			}}
		/>
	);
};

type Subcontractor = SubcontractorFormObject & {
	id: number;
	constraintsString: string;
	trailerTypesString: string;
	truckTypesString: string;
};
const defaultColumns: TypedGridColumnProps<Subcontractor>[] = [
	{ field: "name", title: "Name" },
	{ field: "notes", title: "Notes" },
	{ field: "constraintsString", title: "Constraints" },
	{ field: "emails", title: "Emails" },
	{ field: "phones", title: "Phones" },
	{ field: "trailerTypesString", title: "Trailer Types" },
	{ field: "truckTypesString", title: "Truck Types" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<Subcontractor>
>["data"] => {
	const _subcontractors = useQuery({
		queryKey: ["jobApi.subcontractor.subcontractorList"],
		queryFn: () =>
			jobApi.subcontractor.subcontractorList({}).then((x) => x.data.data),
		initialData: [],
	});
	const subcontractors = useMemo(
		() =>
			_subcontractors.data.map((x): Subcontractor => {
				return {
					id: x.id,
					name: x.name,
					notes: x.notes ?? undefined,
					emails: x.emails,
					phones: x.phones,
					trailerTypeIds: x.trailerTypes.map((x) => x.id),
					truckTypeIds: x.truckTypes.map((x) => x.id),
					constraintIds: x.constraints.map((x) => x.id),
					trailerTypesString: x.trailerTypes.map((x) => x.name).join(", "),
					truckTypesString: x.truckTypes.map((x) => x.name).join(", "),
					constraintsString: x.constraints.map((x) => x.name).join(", "),
				};
			}),
		[_subcontractors.data],
	);
	return {
		data: subcontractors,
		retry: _subcontractors.refetch,
		loading: _subcontractors.isFetching,
	};
};
export const SubcontractorsPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			jobApi.subcontractor.subcontractorDelete(id).then(data.retry),
			"Deleting Subcontractor",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: SubcontractorFormObject) => void,
	) => {
		let defaultValues: Partial<SubcontractorFormObject> = {};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<SubcontractorFormWithDTO
				onSubmit={onSubmit}
				defaultValues={defaultValues}
			/>
		);
	};
	return (
		<GenericPage
			pageTitle="Subcontractors"
			name="Subcontractor"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};
